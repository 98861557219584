import React from "react";

function Phd() {
  return (
    <div className="phd-dropdown-content">
      
        <p>PhD Admission</p>
        <p>UGC NET Preparation</p>
        <p>Honorary Doctorate</p>
        <p>Thesis Writing</p>
      
    </div>
  );
}

export default Phd;
